body{
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    background-color: #010001;
    color: white;
    height: 100vh;
  }

  section{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .intro-section{
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  #root{
    height: 100%;
  }

  .App{  
    height: 100%;
  }

  nav{
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #010001;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    padding: 20px 0px;
  }
  
  .nofoundwrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;

  }
  
  .logo-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo-big{
    height: 50px;
  }
  
  .logo{
    height: 38px;
  }
  
  .nav-links{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .nav-link{
    margin-left: 10px;
    color: white;
    text-decoration: none;
    transition: all 250ms ease;
  }
  
  .nav-link:hover{
    margin-left: 10px;
    color: white;
    transition: all 250ms ease;
  }
  
  .nav-link-2{
    margin-left: 10px;
    color: white;
    text-decoration: none;
    transition: all 250ms ease;
  }
  
  .nav-link-2:hover{
    margin-left: 10px;
    color: white;
    transition: all 250ms ease;
  }
  
  .nav-cont{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .container{
    width: 1200px;
    max-width: 1200px;
  }
  
  h1{
    font-size: 62px;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  h2{
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .work-heading{
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 10px;
    margin-top: 0px;
  }
  
  .subtitle{
    font-size: 18px;
    line-height: 25px;
    font-weight: 400;
    color: #a8a8a8;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  
  .work-grid{
    display: grid;
    grid-column-gap: 60px;
    grid-row-gap: 60px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    margin-top: 30px;
    margin-bottom: 50px;
  }
  
  .project-details{
    padding: 30px;
  }
  
  .other-projects{
    height: 620px;
    background-size: cover;
    background-position: center center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: white;
    text-decoration: none;
    padding: 30px;
  }
  
  .other-project{
    width: calc(100% - 60px);
    padding: 30px;
    color: white;
    text-decoration: none;
  }
  
  .contact{
    margin-top: 50px;
    margin-bottom: 75px;
  }
  
  .other-project:hover{
    background-color: #1a1a1a;
    border-radius: 5px;
    transition: all 250ms ease;
  }
  
  .contact-link{
    height: 21px;
    font-size: 18px;
    font-weight: 400;
    margin-top: 10px;
    padding: 10px;
    display: inline-block;
    color: white;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .contact-link:hover{
    background-color: #1a1a1a;
    border-radius: 5px;
    transition: all 250ms ease;
  }
  
  .legal{
    padding: 25px 0px;
  }
  
  .horizontal-div{
    display: flex;
    flex-direction: row;
  }
  
  .horizontal-between{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .img{
    margin-right: 10px;
  }
  
  .button{
    cursor: pointer;
  }
  
  button{
    cursor: pointer;
    margin-top: 20px;
    background-color: #1a1a1a;
    border-radius: 5px;
    transition: all 250ms ease;
    padding: 15px 35px;
    font-size: 18px;
    color: white;
    border: none;
    outline: none;
  }
  
  .companies-grid{
    max-width: 100%;
    display: grid;
    grid-column-gap: 60px;
    grid-row-gap: 60px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    margin-top: 30px;
    margin-bottom: 50px;
  }

  .companies-wrapper{
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .dreampurpose-logo{
    -webkit-filter: invert(1);
    filter: invert(1);
  }

  .companies{
    width: 70%;
  }



  .nav-button{
    cursor: pointer;
    background-color: #1a1a1a;
    border-radius: 5px;
    transition: all 250ms ease;
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    border: none;
    outline: none;
    margin-top: 0px;
    text-decoration: none;
  }
  
  .nav-button-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    text-decoration: none;
  }
  
  /*items*/
  
  .deepblue{
    background-image:
    linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.9)),
    url('../img/deepblue.png');
  
    height: 620px;
    background-size: cover;
    background-position: center center;
    display: flex;
    align-items: flex-end;
    color: white;
    text-decoration: none;
    transition: all 250ms ease;
  }
  
  .deepblue:hover{
    filter: brightness(1.5);
    transition: all 250ms ease;
  }
  
  .gamefi{
    background-image:
    linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.8)),
    url('../img/gamefi.png');
    background-position: 0px 0px, 30% 50%;
  
    height: 620px;
    background-size: cover;
    display: flex;
    align-items: flex-end;
    color: white;
    text-decoration: none;
    transition: all 250ms ease;
  }
  
  .gamefi:hover{
    filter: brightness(1.5);
    transition: all 250ms ease;
  }
  
  .dakxl{
    background-image:
    linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.8)),
    url('../img/dakxl.jpeg');
  
    height: 620px;
    background-size: cover;
    background-position: center center;
    display: flex;
    align-items: flex-end;
    color: white;
    text-decoration: none;
    transition: all 250ms ease;
  }
  
  .dakxl:hover{
    filter: brightness(1.5);
    transition: all 250ms ease;
  }
  
  
  /* responsiveness */
  @media screen and (max-width: 1200px) {
  
    .companies-grid{
      max-width: 100%;
      display: grid;
      grid-column-gap: 60px;
      grid-row-gap: 60px;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: auto;
      margin-top: 30px;
      margin-bottom: 50px;
    }

    .companies{
      width: 150px;
    }

    .work-grid{
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
        margin-top: 30px;
        margin-bottom: 50px;
    }


  
    .container{
        width: 90%;
        max-width: 90%;
  
    }
  
    h1{
        font-size: 40px;
    }
    
    h2{
        font-size: 14px;
        line-height: 20px;
    }
  
    .subtitle{
        font-size: 14px;
    }
  }
    
  @media screen and (max-width: 735px){
    h1 br { content: ''; }
    h2 br { content: ' '; }
  
    .companies-grid{
      max-width: 100%;
      display: grid;
      grid-column-gap: 60px;
      grid-row-gap: 60px;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: auto;
      margin-top: 30px;
      margin-bottom: 50px;
    }

    .companies{
      width: 60%;
    }

    .horizontal-between{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
    
    .horizontal-div{
      flex-direction: column;
    }
  
    button{
      font-size:16px;
      width: 100%;
    }
  
    h1{
      font-size: 25px;
    }
  
    .nav-link{
      display: none;
    }
  
    .logo{
      height: 25px;
    }
  
    nav{
      padding: 10px 0px;
    }
  }

  @media screen and (max-width: 500px){
    .deepblue{
      background-image:
      linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.9)),
      url('../img/deepblue-mobile.png');
      background-size: cover;
      background-repeat: no-repeat;
    }

    .companies-grid{
      max-width: 100%;
      display: grid;
      grid-column-gap: 0px;
      grid-row-gap: 30px;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      margin-top: 30px;
      margin-bottom: 80px;
    }

    .companies{
      width: 80%;
    }
  }
  